import React, { Component } from "react";
import firestore from "../../firestore";
import { Link } from "react-router-dom";
import moment from "moment";
import Icon from "./Icon";

const News = props => {
  const {
    title,
    keywords_nltk,
    summary_nltk,
    image_name,
    author,
    upload_date,
    link
  } = props.data;
  const date = moment(upload_date.toDate()).format("MMMM Do YYYY");
  const classes = props.classes || "article-item";
  return (
    <div className={classes} style={{ borderRadius: "10px" }}>
      <div
        className="article-img"
        style={{ backgroundImage: `url(${image_name})` }}
      >
        {/* <div className="taggroup">
          <div className="btn btn-blockchain tag">BLOCKCHAINs</div>
        </div> */}
        <div
          style={{ fontSize: "25px", color: "#fff", lineHeight: "1em" }}
          className="article-title"
        >
          <a
            style={{ color: "#fff", textDecoration: "none" }}
            href={link}
            target="_blank"
          >
            {title}
          </a>
        </div>
      </div>
      <div className="article-group">
        {/* <div className="article-source" style={{textAlign:'justify'}}>source : <a style={{color: '#0150a0',textDecoration: 'none'}} href={link} target="_blank">{link}</a></div> */}
        <div className="article-detail">
          <div className="article-date">
            <i className="fa fa-clock-o" />
            &nbsp;
            {date}
          </div>
          {author.length > 0 && (
            <div className="article-author">
              <i className="fa fa-user" />
              &nbsp; by {author.length > 0 ? author[0] : "unknown"}
            </div>
          )}
        </div>
        <div className="article-content" style={{ textAlign: "justify" }}>
          {summary_nltk}
        </div>
        <div className="article-link">
          <div className="article-linkdet">
            <div>
              {props.icons.length > 0 &&
                keywords_nltk.map((keyword, i) => (
                  <Icon icons={props.icons} key={i} tag={keyword} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
