import React from 'react';
import InstagramEmbed from 'react-instagram-embed';

const Instagram = props => {
  return (
    <div className="row">
      <div style={{ display: 'table', margin: '0 auto', width: '100%' }}>
        <div className="instagram col-md-6">
          {props.instagram &&
            props.instagram.slice(0, 2).map((data, i) => (
              <InstagramEmbed
                key={data.link}
                url={data.link}
                // hideCaption={false}
                // containerTagName="div"
                // protocol=""
                // injectScript
                // onLoading={() => {}}
                // onAfterRender={() => {}}
              />
            ))}
        </div>
        <div className="instagram col-md-6 margin-left-ig">
          {props.instagram &&
            props.instagram.slice(2).map((data, i) => (
              <InstagramEmbed
                url={data.link}
                key={data.link}
                // hideCaption={false}
                // containerTagName="div"
                // protocol=""
                // injectScript
                // onLoading={() => {}}
              
                // onAfterRender={() => {}}
               
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Instagram;
