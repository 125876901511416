import React, { Component } from 'react';
import { oneIcon, getImage } from '../../helpers/Helper';

import db, { storage } from '../../firestore';
import Slides from './Slides';
import './Newtop.css';

export default class TopSection extends Component {
  state = {
    slides: [{ imageURL: '' }],
    slideNow: {},
    loading: false,
    slide: {},
    clicked: {
      id: '',
      isActive: false
    }
  };

  componentDidMount() {
    this.getSlides();
  }

  next = () => {
    if (this.state.slideNow.position_number == this.state.slides.length) return;
    this.setState({ loading: true }, () => {
      this.setState({
        slideNow: {
          ...this.state.slides[this.state.slideNow.position_number]
        },
        loading: false
      });
    });
  };

  prev = () => {
    if (this.state.slideNow.position_number == 1) return;
    this.setState({ loading: true }, () => {});
    this.setState({
      slideNow: {
        ...this.state.slides[this.state.slideNow.position_number - 2]
      },
      loading: false
    });
  };

  getSlides = () => {
    db.collection('CFN_intelligence')
      .orderBy('position_number')
      .onSnapshot(snapshot => {
        const slides = [];
        const promises = [];
        snapshot.forEach(data => {
          const slide = data.data();
          const action = storage.refFromURL(slide.imageURL).getDownloadURL();
          promises.push(action);
          slides.push({ ...slide, id: data.id });
        });
       
        Promise.all(promises).then(values => {
          for (let i = 0; i < promises.length; i++) {
            slides[i].imageURL = values[i];
          }
          this.setState({ slides });
        });
      });
  };

  clickHandler = title => {
    this.setState({
      clicked: {
        title: title,
        isActive: !this.state.clicked.isActive
      }
    });
  };
  render() {
    const { topNews } = this.props;
    const { clicked } = this.state;
    const loaded =
      topNews.length > 0 && this.props.icons.length > 0 ? true : false;
    return (
      <div>
        <div className="Newtop-desktop">
          <div className="Newtop">
            <div className="Newtop-row">
              <div
                onClick={() => this.clickHandler(topNews[0].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[0].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[0].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[0].title}</h1>}
                </div>

                {clicked.title == topNews[0].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[0].summary_nltk}</p>
                      <a href={loaded ? topNews[0].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>

              <div className="Newtop-col-40">
                <Slides slides={this.state.slides} />
              </div>

              <div
                onClick={() => this.clickHandler(topNews[1].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[1].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[1].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[1].title}</h1>}
                </div>
                {clicked.title === topNews[1].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[1].summary_nltk}</p>
                      <a href={loaded ? topNews[1].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => this.clickHandler(topNews[2].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[2].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[2].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[2].title}</h1>}
                </div>
                {clicked.title === topNews[2].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[2].summary_nltk}</p>
                      <a href={loaded ? topNews[2].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="Newtop-row">
              <div
                onClick={() => this.clickHandler(topNews[3].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[3].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[3].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[3].title}</h1>}
                </div>
                {clicked.title === topNews[3].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[3].summary_nltk}</p>
                      <a href={loaded ? topNews[3].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => this.clickHandler(topNews[4].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[4].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[4].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[4].title}</h1>}
                </div>
                {clicked.title === topNews[4].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[4].summary_nltk}</p>
                      <a href={loaded ? topNews[4].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => this.clickHandler(topNews[5].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[5].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[5].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[5].title}</h1>}
                </div>
                {clicked.title === topNews[5].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[5].summary_nltk}</p>
                      <a href={loaded ? topNews[5].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => this.clickHandler(topNews[6].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[6].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[6].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[6].title}</h1>}
                </div>
                {clicked.title === topNews[6].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[6].summary_nltk}</p>
                      <a href={loaded ? topNews[6].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
              <div
                onClick={() => this.clickHandler(topNews[7].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[7].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[7].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[7].title}</h1>}
                </div>
                {clicked.title === topNews[7].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[7].summary_nltk}</p>
                      <a href={loaded ? topNews[7].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Newtop-phone">
          <div className="row">
            <div className="col-xs-12">
              <div className="Newtop-col-40">
                <Slides slides={this.state.slides} />
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[0].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[0].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[0].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[0].title}</h1>}
                </div>

                {clicked.title === topNews[0].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[0].summary_nltk}</p>
                      <a href={loaded ? topNews[0].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[1].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[1].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[1].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[1].title}</h1>}
                </div>
                {clicked.title === topNews[1].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[1].summary_nltk}</p>
                      <a href={loaded ? topNews[1].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[2].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[2].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[2].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[2].title}</h1>}
                </div>
                {clicked.title === topNews[2].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[2].summary_nltk}</p>
                      <a href={loaded ? topNews[2].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[3].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[3].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[3].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[3].title}</h1>}
                </div>
                {clicked.title === topNews[3].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[3].summary_nltk}</p>
                      <a href={loaded ? topNews[3].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[4].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[4].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[4].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[4].title}</h1>}
                </div>
                {clicked.title === topNews[4].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[1].summary_nltk}</p>
                      <a href={loaded ? topNews[4].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[5].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[5].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[5].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[5].title}</h1>}
                </div>
                {clicked.title === topNews[5].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[5].summary_nltk}</p>
                      <a href={loaded ? topNews[5].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[6].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[6].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[6].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[6].title}</h1>}
                </div>
                {clicked.title === topNews[6].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[6].summary_nltk}</p>
                      <a href={loaded ? topNews[6].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-xs-6">
              <div
                onClick={() => this.clickHandler(topNews[7].title)}
                className="Newtop-col-20"
                tabindex="0"
                style={{
                  backgroundImage: `url(${loaded && topNews[7].image_name})`
                }}
              >
                <div className="Newtop-high">
                  {loaded && (
                    <img
                      src={oneIcon(topNews[7].keywords_nltk, this.props.icons)}
                      className="Newtop-icon"
                    />
                  )}
                  {loaded && <h1>{topNews[7].title}</h1>}
                </div>
                {clicked.title === topNews[7].title && clicked.isActive && (
                  <div className="Newtop-hover">
                    <div>
                      <p>{loaded && topNews[7].summary_nltk}</p>
                      <a href={loaded ? topNews[7].link : null} target="_blank">
                        Full Story
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
