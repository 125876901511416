import React from "react";

const About = () => {
  return (
    <>
      <link rel="stylesheet" href="assets/css/style.css" />
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
      />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js" />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js" />
      <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js" />
      <script src="https://use.fontawesome.com/releases/v5.0.8/js/all.js" />
      <div className="container-fluid about">
        <div className="row about-title text-center">
          <div className="col-12">
            <h1>
              <span className="orange">A</span>BOUT US
            </h1>
          </div>
          <hr />
        </div>
      </div>

      <div className="arrow">
        <img src="images/arrow.png" />
      </div>

      <div className="container-fluid description">
        <div className="row description-content text-center">
          <div className="col-12">
            <p>
              <span className="orange">
                <b>C</b>
              </span>
              rypto Flows News is designed to give crypto enthusiasts hand
              curated news and social feeds covering global
              <br />
              crypto currencies and emerging blockchain technologies. With a
              specific focus on the most widely traded global
              <br />
              cryptos included in the <b>Crypto Flow Index (CFI 20)</b>. We
              eliminate the noise, verify and link together news and social,
              <br />
              so you know what drives the global crypto markets today. Plus
              research and propietary data feeds that make it
              <br />
              easy to understand what you own and why it matters.
            </p>
          </div>
        </div>
      </div>

      <div className="container-fluid feature">
        <div className="row text-center feature-items">
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="feature-icons">
              <i className="fas fa-chart-bar" />
            </div>
            <hr />
            <div className="feature-desc">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                pulvinar ligula justo, id lacinia sapien fermentum vel. Class
                aptent taciti sociosqu ad litora torquent per conubia nostra,
                per inceptos himenaeos. Curabitur sed tortor in turpis bibendum
                faucibus sit amet at orci. Curabitur efficitur lobortis
                facilisis.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="feature-icons">
              <i className="fas fa-volume-up" />
            </div>
            <hr />
            <div className="feature-desc">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                pulvinar ligula justo, id lacinia sapien fermentum vel. Class
                aptent taciti sociosqu ad litora torquent per conubia nostra,
                per inceptos himenaeos. Curabitur sed tortor in turpis bibendum
                faucibus sit amet at orci. Curabitur efficitur lobortis
                facilisis.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div className="feature-icons">
              <i className="fas fa-users" />
            </div>
            <hr />
            <div className="feature-desc">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                pulvinar ligula justo, id lacinia sapien fermentum vel. Class
                aptent taciti sociosqu ad litora torquent per conubia nostra,
                per inceptos himenaeos. Curabitur sed tortor in turpis bibendum
                faucibus sit amet at orci. Curabitur efficitur lobortis
                facilisis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
