import React from "react";
import { tagedIcon } from "../../helpers/Helper";

function Icon(props) {
  return (
    <>
      {props.icons.length > 0 &&
      props.tag &&
      tagedIcon(props.tag, props.icons) ? (
        <span style={{ marginLeft: "1rem", display: "inline-block" }}>
          {/* {props.tag} */}
          <img
            src={tagedIcon(props.tag, props.icons)}
            className="iconNews"
          />
        </span>
      ) : (
       null
      )}
    </>
  );
}

export default Icon;
