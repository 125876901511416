import React from 'react';
import './Footer.css';

const Footer = () => {
  const today = new Date();
  return (
    <div id="section-four">
      {/* <div className="subscribe">
        <div className="subscribe-title">
          Subscribe to the High Five Intelligence Report!
        </div>
        <div className="subscribe-input">
          <input
            type="email"
            name="subscribe-email"
            placeholder="Your email here"
          />
          <button>
            <i className="fa fa-paper-plane" />
          </button>
        </div>
      </div> */}
      <div className="footer">
        <div className="footer-logogroup">
          <img
            src={require('../assets/img/footer/cfi-logo-dark@3x.png')}
            className="footer-logo"
          />
        </div>
        <div className="footer-menu">
          <a href="https://medium.com/@cryptoflowintel/crypto-flow-intelligence-v0-01-1b404cca9b02">Inteligence</a>
          <a href="">Media</a>
          <a href="https://controlpanel.keymajorcoin.com">Control Panel</a>
          <a href="https://marketcap.keymajorcoin.com">Marketcap</a>
        </div>
      </div>
      <div className="footstyle">
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12 copyright">
          <div>
            Copyright ©{today.getFullYear()} Key Major Coin. All Rights
            Reserved.
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 contactus">
          <div>Contact Us</div>
        </div> */}
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 contactus">
          <div>Terms of Service and Privacy Policy</div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 contactus">
          <div>
            <a href="https://t.me/KMC_intelReport">
            <i className="fab fa-telegram-plane footerLinkIcon"/>
            </a>
            <a href="https://twitter.com/KeyMajorCoin">
            <i className="fab fa-twitter footerLinkIcon"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
