import React from 'react'
import YouTube from "react-youtube";
import { getYoutubeId } from '../../helpers/Helper'


const Youtube = (props) => {
  return (   
    <div className="row">     
      <div style={{ display: "table", margin: "0 auto", width: "100%"}}>
        <div className="youtube col-md-6">

          {props.youtube &&
            props.youtube.slice(0, 6).map((data, i) => (
              <YouTube
              key={data.link}
              opts={{ height: "100%", width: "100%"}}
              videoId={getYoutubeId(data.link)}
            />
            ))}

        </div>
        <div className="youtube col-md-6">
          {props.youtube &&
            props.youtube.slice(6).map((data, i) => (
              <YouTube
              key={data.link}
              opts={{ height: "100%", width: "100%" }}
              videoId={getYoutubeId(data.link)}
            />
            ))}
        </div>
      </div>
    </div>
  )
}

export default Youtube
