import React, { Component } from "react";
import Home from "./Containers/Home";
import DetailNews from "./Components/News/DetailNews";
import Navbar from "./Components/Navbar";
import About from './Components/About/About';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import TestSocmed from "./Components/Social/TestSocmed";

class App extends Component {
  render() {
    return (
      <Router>
        <>
        <Switch>
            <Route exact path="/about" component={About} />
          </Switch>
        <>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/news/:id" component={DetailNews} />
            <Route exact path="/test" component={TestSocmed} />
            {/* <Route exact path="/about" component={About} /> */}
          </Switch>
          </>
        </>
      </Router>
    );
  }
}

export default App;
