import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './Slides.css';

const DemoCarousel = props => (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      emulateTouch
      interval={3000}
      infiniteLoop
      autoPlay={false}
    >
      {props.slides.map((slide, index) => (
          <div key={index}>
            <img src={slide.imageURL} />
          </div>
        ))}
    </Carousel>

);

export default DemoCarousel;
