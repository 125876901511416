import React, { Component } from 'react';
import db, { storage } from '../../firestore';
import { getSymbol } from '../../helpers/Helper';
import { NavLink, Link } from 'react-router-dom';
import './NavbarCrypto2.css';
import { CFConvert } from '../../helpers/Helper';

class NavbarCrypto extends Component {
  state = {
    cfix: null,
    cryptos: [],
    icons: [],
    qtum: {}
  };

  componentDidMount() {
    this.getCryptos();
    this.getIcons();
  }

  getIcons = () => {
    const icons = [];
    db.collection('CFN_icons').onSnapshot(snapshot => {
      snapshot.forEach(data => {
        icons.push(data.data());
      });
      this.setState({ icons: icons });
    });
  };

  getCryptos = () => {
    db.collection('CFIx_5min')
      .limit(17)
      .onSnapshot(snapshots => {
        const tempObject = {};
        snapshots.forEach(snapshot => {
          tempObject[snapshot.id] = {
            id: snapshot.id,
            ...snapshot.data()[Object.keys(snapshot.data()).reverse()[0]]
          };
        });

        let cryptos = Object.entries(tempObject).map(obj => obj[1]);
        cryptos.sort((a, b) => a.order - b.order);
        const CFI = cryptos.find(c => c.id === 'CFIxCFIx');
        cryptos = cryptos.filter(d => d.id !== "CFIxCFIx")
        this.setState({ cryptos, cfix: CFI });
      });
  };

  render() {
    const { cryptos, cfix, icons } = this.state;
    const cfixClass = 'fa fa-chevron-down red';

    return (
      <>
        <div className="navCrypto">
          <div className="navCrypto-mobile">
            <div className="navCrypto__cf">
              <div className="navCrypto__cfCoin">
                <img
                  src={require('../../assets/img/navbar/cfi.png')}
                  alt="cfilogo"
                />
                <div className="navCrypto__cfCoin__text">
                  <p className="navCrypto__cfCoin__text-name">
                    CFIx
                    <img
                      src={
                        cfix && cfix.pcp > 0
                          ? require('../../assets/img/navbar/icon-up-green.png')
                          : require('../../assets/img/navbar/icon-dn-red.png')
                      }
                      alt="arrow"
                    />
                  </p>
                  <p className="navCrypto__cfCoin__text-price">
                    {cfix && cfix.close.toFixed(3)}
                  </p>
                </div>
              </div>
            </div>
            <div className="navCrypto__btnCont">
              <button
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                See Crypto
              </button>
            </div>
          </div>
          <div className="collapse navCrypto-collapse" id="collapseExample">
            <div className="navCrypto__coins">
              {cryptos.map(crypto => (
                <div className="navCrypto__coin">
                  <div className="navCrypto__coin-name">
                    {CFConvert(crypto.id)}
                  </div>
                  <div className="navCrypto__coin-price">
                    <span
                      className={
                        crypto && crypto.pcp > 0 ? 'text--green' : 'text--red'
                      }
                    >
                      {crypto && '$'}
                    </span>
                    {crypto && crypto.close.toFixed(3)}
                  </div>
                  <div className="navCrypto__coin-img">
                    <img
                      src={
                        getSymbol(crypto.id, icons) ||
                        'https://loading.io/spinners/typing/lg.-text-entering-comment-loader.gif'
                      }
                      alt="coinlogo"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="navCrypto-desktop">
            <div className="navCrypto__coins">
              {cryptos.slice(0, 8).map((crypto, i) => (
                <>
                  <div className="navCrypto__coin">
                    <div className="navCrypto__coin-name">
                      {CFConvert(crypto.id)}
                    </div>
                    <div className="navCrypto__coin-price">
                      <span
                        className={
                          crypto && crypto.pcp > 0 ? 'text--green' : 'text--red'
                        }
                      >
                        {crypto && '$'}
                      </span>
                      {crypto && crypto.close.toFixed(3)}
                    </div>
                    <div className="navCrypto__coin-img">
                      <img
                        src={
                          getSymbol(crypto.id, icons) ||
                          'https://loading.io/spinners/typing/lg.-text-entering-comment-loader.gif'
                        }
                        alt="coinlogo"
                      />
                    </div>
                  </div>
                  {i !== 7 && <div className="navCrypto__divider">
                    <div className="line" />
                  </div>}
                </>
              ))}

              <div className="navCrypto__lead">
                <div className="navCrypto__cfCoin">
                  <img
                    src={require('../../assets/img/navbar/cfi.png')}
                    alt="cfilogo"
                  />
                  <div className="navCrypto__cfCoin__text">
                    <p className="navCrypto__cfCoin__text-name">
                      CFx
                      <img
                        src={
                          cfix && cfix.pcp > 0
                            ? require('../../assets/img/navbar/icon-up-green.png')
                            : require('../../assets/img/navbar/icon-dn-red.png')
                        }
                        alt=""
                      />
                    </p>
                    <p className="navCrypto__cfCoin__text-price">
                      {cfix && cfix.close.toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              {cryptos.slice(8, 16).map((crypto, i) => (
                <>
                  <div className="navCrypto__coin">
                    <div className="navCrypto__coin-name">
                      {CFConvert(crypto.id)}
                    </div>
                    <div className="navCrypto__coin-price">
                      <span
                        className={
                          crypto && crypto.pcp > 0 ? 'text--green' : 'text--red'
                        }
                      >
                        {crypto && '$'}
                      </span>{' '}
                      {crypto && crypto.close.toFixed(3)}
                    </div>
                    <div className="navCrypto__coin-img">
                      <img
                        src={
                          getSymbol(crypto.id, icons) ||
                          'https://loading.io/spinners/typing/lg.-text-entering-comment-loader.gif'
                        }
                        alt="coinlogo"
                      />
                    </div>
                  </div>
                  {i !== 15 && <div className="navCrypto__divider">
                    <div className="line" />
                  </div>}
                </>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NavbarCrypto;
