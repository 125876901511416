import React, { Component } from 'react';
import TopSection from '../Components/TopSection/TopSection';
import Footer from '../Components/Footer';
import NewsWrapper from '../Components/News/NewsWrapper';
import db from '../firestore';

export default class Home extends Component {
  state = {
    articles: [],
    topNews: [
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      },
      {
        title: '',
        image_name: '',
        keywords_nltk: []
      }
    ],
    icons: []
  };

  getArticles = () => {
    let articles = [];
    db.collection('CFN_article')
      .where('top_news', '==', false)
      .orderBy('upload_date', 'desc')
      .orderBy('seq_nmbrs', 'desc')
      .limit(8)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const article = data.data();
          article.id = data.id;
          articles.push(article);
        });
        this.setState({ articles: articles });
        articles = []
      });
  };

  getIcons = () => {
    let icons = [];
    db.collection('CFN_icons').onSnapshot(snapshot => {
      snapshot.forEach(data => {
        icons.push(data.data());
      });
      this.setState({ icons: icons });
      icons = []
    });
  };

  getTopArticles = () => {
    let topNews = [];
    db.collection('CFN_article')
      .where('top_news', '==', true)
      .orderBy('top_position')
      .limit(8)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const article = data.data();
          topNews.push(article);
        });
        if (topNews.length < 8) {
          topNews = [
            ...topNews,
            {
              title: 'No Data For Top 7',
              image_name: '',
              keywords_nltk: []
            },
            {
              title: 'No Data For Top 8',
              image_name: '',
              keywords_nltk: []
            }

          ]
        }
        this.setState({ topNews: topNews });
        topNews = []
      });
  };

  getMoreArticle = async lastArticle => {
    const startAfter =
      lastArticle &&
      (await db
        .collection('CFN_article')
        .doc(lastArticle.id)
        .get());

    let articles = [];
    db.collection('CFN_article')
      .where('top_news', '==', false)
      .orderBy('upload_date', 'desc')
      .orderBy('seq_nmbrs', 'desc')
      .startAfter(startAfter)
      .limit(8)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const article = data.data();
          article.id = data.id;
          articles.push(article);
        });
        this.setState({ articles: [...this.state.articles, ...articles] });
        articles = []
      });
  };

  componentDidMount() {
    this.getArticles();
    this.getTopArticles();
    this.getIcons();
  }
  render() {
    return (
      <>
        <TopSection icons={this.state.icons} topNews={this.state.topNews} />
        <NewsWrapper
          getMoreArticle={this.getMoreArticle}
          articles={this.state.articles}
          icons={this.state.icons}
        />
        <Footer />
      </>
    );
  }
}
