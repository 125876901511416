import React, { Component } from 'react';
// import YouTube from 'react-youtube-embed'
import db from '../../firestore';

import Instagram from './Instagram';
import Twitter from './Twitter';
import Youtube from './Youtube';
import './Social.css';

class Social extends Component {
  state = {
    socialMedia: 'Youtube',
    youtube: [],
    twitter: [],
    instagram: []
  };

  onChangeHanlder = e => {
    this.setState({ socialMedia: e });
  };
  getYoutube = () => {
    let youtubeData = [];
    db.collection('CFN_youtube')
      .orderBy('upload_date', 'desc')
      .orderBy('seq_nmbrs')
      .limit(12)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const snap = data.data();
          youtubeData.push(snap);
        });
        this.setState({ youtube: youtubeData });
        youtubeData = [];
      });
  };

  getTwitter = () => {
    let youtubeData = [];
    db.collection('CFN_twt')
      .orderBy('upload_date', 'desc')
      .orderBy('seq_nmbrs')
      .limit(6)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const snap = data.data();
          youtubeData.push(snap);
        });
        this.setState({ twitter: youtubeData });
        youtubeData = [];
      });
  };

  getInstagram = () => {
    let youtubeData = [];
    db.collection('CFN_insta')
      .orderBy('upload_date', 'desc')
      .orderBy('seq_nmbrs')
      .limit(4)
      .onSnapshot(snapshot => {
        snapshot.forEach(data => {
          const snap = data.data();
          youtubeData.push(snap);
        });
        this.setState({ instagram: youtubeData });
        youtubeData = [];
      });
  };

  componentDidMount() {
    this.getYoutube();
    this.getTwitter();
    this.getInstagram();
  }
  render() {
    const { socialMedia } = this.state;
    return (
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div className="News-wrapper-header">
          <div className="News-wrapper-line-area">
          <img src={require('../../assets/img/news/line-lboarder@3x.png')} alt="line" className="News-wrapper-line"/>
          </div>
          <div className="News-wrapper-title">
          curated social
          <div className="News-wrapper-subtitle">
          analyst's top social
          </div>
          </div>
          </div>
        <div className="socmed-menu">
          <div className="socmed-menuUP">
            <div
              onClick={() => this.onChangeHanlder('Youtube')}
              className={
                socialMedia === 'Youtube' ? 's-desktop activeSM Youtube' : 's-desktop'
              }
            >
              <i class="fab fa-youtube Youtube"></i>
            </div>
            <div
              onClick={() => this.onChangeHanlder('Twitter')}
              className={
                socialMedia === 'Twitter' ? 's-desktop activeSM Twitter' : 's-desktop'
              }
            >
              <i class="fab fa-twitter Twitter"></i>
            </div>
            <div
              onClick={() => this.onChangeHanlder('Instagram')}
              className={
                socialMedia === 'Instagram' ? 's-desktop activeSM Instagram' : 's-desktop'
              }
            >
              <i class="fab fa-instagram Instagram"></i>
            </div>
          </div>

          <div className="socmed-menuUP s-mobileG">
            <div className="s-mobile activeSM s-show" link="#" id="s1">
              <i className="fa fa-rotate-left" /> Recent Updates
            </div>
            <div className="s-mobile activeSM" link="#" id="s2">
              Youtube
            </div>
            <div className="s-mobile activeSM" link="#" id="s3">
              Twitch
            </div>
            <div className="s-mobile activeSM" link="#" id="s4">
              Instagram
            </div>
            <div className="s-mobile activeSM" link="#" id="s5">
              Facebook
            </div>
            <div className="s-mobile activeSM" link="#" id="s6">
              Twitter
            </div>
            <div className="s-mobilearrowG">
              <button className="s-mobilearrow" id="s-left">
                <i className="fa fa-angle-left" />
              </button>
              <button className="s-mobilearrow" id="s-right">
                <i className="fa fa-angle-right" />
              </button>
            </div>
          </div>

          <div className="socmed-menuDO">
            <form>            
              <div className="socmed-formL">
                <div className="filter-radio">
                  <div className="radio-group">
                    <input type="radio" name="" /> <div>All</div>
                  </div>
                  <div className="radio-group">
                    <input type="radio" name="" /> <div>Most Recent</div>
                  </div>
                  <div className="radio-group">
                    <input type="radio" name="" /> <div>Most Popular</div>
                  </div>
                  <div className="radio-group">
                    <input type="radio" name="" /> <div>Top Stories</div>
                  </div>
                  <div className="radio-group">
                    <input type="radio" name="" /> <div>Most Liked</div>
                  </div>
                </div>
              </div>

              <div className="socmed-formR">
                <div className="se-group">
                  <select>
                    <option>Categories</option>
                  </select>
                  <span className="fa fa-caret-down" />
                </div>
                <div className="s-group">
                  <span className="fa fa-search" />
                  <input type="text" name="" placeholder="Search" />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="mansonry">
        <div className="Social-scroll">
          {this.state.socialMedia === 'Youtube' ? (
            <Youtube youtube={this.state.youtube} />
          ) : this.state.socialMedia === 'Twitter' ? (
            <Twitter twitter={this.state.twitter} />
          ) : (
            <Instagram instagram={this.state.instagram} />
          )}
          </div>
        </div>
      </div>
    );
  }
}

export default Social;
