import React from "react";

const LoadMore = props => {
  return (
    <div id="section-three">
      <button onClick={() => props.getMoreArticle(props.lastArticle)} id="btn-showmore">SHOW MORE</button>
    </div>
  );
};

export default LoadMore;
