import React, { Component } from "react";
import News from "./News";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import LoadMore from "../UI/LoadMore";
import Social from '../Social/Social';
import './News.css';

class NewsWrapper extends Component {
  render() {
    const { articles, getMoreArticle } = this.props;
    return (
      <>
        <div id="section-two">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="News-wrapper-header">
          <div className="News-wrapper-line-area">
          <img src={require('../../assets/img/news/line-lboarder@3x.png')} alt="line" className="News-wrapper-line"/>
          </div>
          <div className="News-wrapper-title">
          News River
          <div className="News-wrapper-subtitle">
          hand curated
          </div>
          </div>
          </div>
          <div className="News-scroll">
            {articles ? (
              articles.slice(0, 2).map(article => (
                <News icons={this.props.icons} key={article.title} data={article} />
              ))
            ) : (
              <h1>Loading ...</h1>
            )}
            </div>
          </div>
           <Social />       
            {articles ? (
              articles.slice(2).map(article => (
                <div className="col-lg-6 article-below-container">
                <News icons={this.props.icons} key={article.title} data={article} classes="article-item2" />
                </div>
              ))
            ) : (
              <h1>Loading ...</h1>
            )}

        </div>
        <LoadMore lastArticle={articles && articles[articles.length - 1]} getMoreArticle={this.props.getMoreArticle}  />
      </>
    );
  }
}

export default NewsWrapper;
