import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import db, { storage } from '../firestore';
import { getSymbol } from '../helpers/Helper';
import NavbarCrypto from './NavbarCrypto/NavbarCrypto';

import './Navbar.css';

class Navbar extends Component {
  state = {
    cfix: [],
    cryptos: [],
    icons: [],
    qtum: {},
    etc: {},
    neo: {},
    iota: {},
    Ada: {},
    trx: {},
    eos: {},
    eth: {},
    btc: {},
    xrp: {},
    bch: {},
    ltc: {},
    xlm: {},
    xmr: {},
    dash: {},
    zec: {}
  };
  componentDidMount() {
    this.getCryptos();
    this.getIcons();
  }

  getIcons = () => {
    const icons = [];
    db.collection('CFN_icons').onSnapshot(snapshot => {
      snapshot.forEach(data => {
        icons.push(data.data());
      });
      this.setState({ icons: icons });
    });
  };

  getCryptos = () => {
    db.collection('CFIx_5min')
      .limit(17)
      .onSnapshot(snapshot => {
        let cryptos = [];
        snapshot.forEach(data => {
          const crypto = data.data();
          const newCrypto = {
            id: data.id,
            data: crypto
          };
          cryptos.push(newCrypto);
        });

        const CFI = cryptos.filter(c => c.id === 'CFIxCFIx');
        const data = cryptos.filter(c => c.id !== 'CFIxCFIx');
        const CFIxQTUM = cryptos.filter(c => c.id === 'CFIxQTUM');
        const CFIxETC = cryptos.filter(c => c.id === 'CFIxETC');
        const CFIxNEO = cryptos.filter(c => c.id === 'CFIxNEO');
        const CFIxIOTA = cryptos.filter(c => c.id === 'CFIxIOTA');
        const CFIxADA = cryptos.filter(c => c.id === 'CFIxADA');
        const CFIxTRX = cryptos.filter(c => c.id === 'CFIxTRX');
        const CFIxEOS = cryptos.filter(c => c.id === 'CFIxEOS');
        const CFIxETH = cryptos.filter(c => c.id === 'CFIxETH');
        const CFIxBTC = cryptos.filter(c => c.id === 'CFIxBTC');
        const CFIxXRP = cryptos.filter(c => c.id === 'CFIxXRP');
        const CFIxBCH = cryptos.filter(c => c.id === 'CFIxBCH');
        const CFIxLTC = cryptos.filter(c => c.id === 'CFIxLTC');
        const CFIxXLM = cryptos.filter(c => c.id === 'CFIxXLM');
        const CFIxXMR = cryptos.filter(c => c.id === 'CFIxXMR');
        const CFIxDASH = cryptos.filter(c => c.id === 'CFIxDASH');
        const CFIxZEC = cryptos.filter(c => c.id === 'CFIxZEC');

        this.setState({
          cryptos: data,
          cfix: CFI,
          qtum: CFIxQTUM[0],
          etc: CFIxETC[0],
          neo: CFIxNEO[0],
          iota: CFIxIOTA[0],
          Ada: CFIxADA[0],
          trx: CFIxTRX[0],
          eos: CFIxEOS[0],
          eth: CFIxETH[0],
          btc: CFIxBTC[0],
          xrp: CFIxXRP[0],
          bch: CFIxBCH[0],
          ltc: CFIxLTC[0],
          xlm: CFIxXLM[0],
          xmr: CFIxXMR[0],
          dash: CFIxDASH[0],
          zec: CFIxZEC[0]
        });
      });
  };
  render() {
    const {
      cryptos,
      cfix,
      icons,
      qtum,
      etc,
      neo,
      iota,
      Ada,
      trx,
      eos,
      eth,
      btc,
      xrp,
      bch,
      ltc,
      xlm,
      xmr,
      dash,
      zec
    } = this.state;
    const cfixClass = 'fa fa-chevron-down red';
    return (
      <>
        <nav className="mainNav">
          <div className="mainNav__toggler">
            <div id="toggle">
              <i className="fa fa-bars" />
            </div>
          </div>
          <div className="mainNav__logo">
            <img
              src={require('../assets/img/navbar/CFI-logo.png')}
              className="logo__cfi"
            />
          </div>
          <div className="mainNav__text">
            <p>Knowledge In Hand | Value You Keep</p>
          </div>
          <div className="mainNav__logo mainNav__logo--right">
            <img
              src={require('../assets/img/navbar/KMC_logo_blk.png')}
              className="logo__kmc"
            />
          </div>
        </nav>
        <div className="container-fluid" id="menu">
          <ul className="nav navbar-nav" id="mobile">
            <li>
              <a target="_blank" href="https://bitcoin-blog.keymajorcoin.com">
                INTELLIGENCE
              </a>
            </li>
            <li>
              <a target="_blank" href="https://media.keymajorcoin.com">
                MEDIA
              </a>
            </li>
            <li>
              <a target="_blank" href="https://controlpanel.keymajorcoin.com">
                CONTROL PANEL
              </a>
            </li>
            <li>
              <a target="_blank" href="https://marketcap.keymajorcoin.com">
                MARKETCAP
              </a>
            </li>
          </ul>
          <ul className="nav navbar-nav" id="desktop" />
        </div>
        <NavbarCrypto />
      </>
    );
  }
}

export default Navbar;
