import React from "react";
import {
  TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton
} from "react-twitter-embed";

import { getTwitterId } from "../../helpers/Helper";

const Twitter = props => {
  return (
    <div className="row">
      <div style={{ display: "table", margin: "0 auto", width: "100%" }}>
        <div className="twitter col-lg-6 col-md-6 col-sm-6">
          {props.twitter &&
            props.twitter.slice(0, 3).map((data, i) => (
              <TwitterTweetEmbed
              key={data.link}
                options={{ height: 1000 }}
                tweetId={getTwitterId(data.link)}
              />
            ))}
        </div>
        <div className="twitter col-lg-6 col-md-6 col-sm-6">
          {props.twitter &&
            props.twitter.slice(3).map((data, i) => (
              <TwitterTweetEmbed
                key={data.link}
                options={{ height: 1000 }}
                tweetId={getTwitterId(data.link)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Twitter;
