import React from 'react'
import InstagramEmbed from "react-instagram-embed";
import {
    TwitterTimelineEmbed,
    TwitterShareButton,
    TwitterFollowButton,
    TwitterHashtagButton,
    TwitterMentionButton,
    TwitterTweetEmbed,
    TwitterMomentShare,
    TwitterDMButton,
    TwitterVideoEmbed,
    TwitterOnAirButton
  } from "react-twitter-embed";
  
  import { getTwitterId } from '../../helpers/Helper'

  import db from '../../firestore'

class TestSocmed extends React.Component {
    state = {
        twitter: []
    }

    getTwitter = () => {
        const youtubeData = []
        db.collection("CFN_twt")
          .orderBy('upload_date')
          .onSnapshot(snapshot => {
            snapshot.forEach(data => {
              const snap = data.data();
              youtubeData.push(snap);
            });
            this.setState({ twitter: youtubeData });
          });
      }

      componentDidMount() {
          this.getTwitter()
      }
    render() {
        return (
            <div className="row">
                <div style={{display:'table', margin: '0 auto'}}>
               <div className="col-md-6" style={{width: '100vh'}}>
                 {
                    this.state.twitter.map((data, i) => (
                        <TwitterTweetEmbed options={{height: 1000}} tweetId={ getTwitterId(data.link)} />
                    ))
                }
               </div>    
               <div className="col-md-6">
                 {
                    this.state.twitter.map((data, i) => (
                        <TwitterTweetEmbed options={{height: 1000}} tweetId={ getTwitterId('https://twitter.com/SlowMist_Team/status/1085441660031361024')} />
                    ))
                }
               </div>
               </div>           
            </div>
          )
    }
 
}

export default TestSocmed
